import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
	firstValueFrom,
	forkJoin,
	mergeMap,
	Observable,
	of,
	Subject
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
	ApplicationModel,
	ApprovalApplicationModel,
	CSPostResponseModel,
	CustomerConsentModel,
	SearchModel,
	TradeLinesModel
} from '@creditsnap/data-models';
import { AuthenticationService } from './authentication.service';
import { APP_CONFIG, APP_ENV_CONFIG, SharedKeyDataService } from '../index';
import { ActivatedRoute } from '@angular/router';
import { CollateralModel, KeyIdentifierModel } from '../../data-models/src';

@Injectable({
	providedIn: 'root'
})
export class ApplicationService {
	API_APPLICATION_URL = '';
	API_BASIC_AUTH_URL = '';
	public model: any = { grant_type: '', username: '', password: '' };
	public approvalApplicationData: ApprovalApplicationModel;
	params: Subject<any> = new Subject<any>();
	queryParams: Subject<any> = new Subject<any>();

	constructor(
		private authenticationService: AuthenticationService,
		private http: HttpClient,
		@Inject(APP_ENV_CONFIG) private environment: any,
		private sharedKeyDataService: SharedKeyDataService,
		@Inject(APP_CONFIG) public appConfig: any
	) {
		this.API_APPLICATION_URL = environment.apiUrl + '/application';
		this.API_BASIC_AUTH_URL = environment.apiUrl;
	}

	getRates(lender: string, loanPurpose: string, product: string, subProductName: string, termBasedLoan: boolean): Observable<any> {
		if (termBasedLoan) {
			return this.http.get<any>(this.API_BASIC_AUTH_URL + `/rates/${this.environment.institutionId}/${lender}/${loanPurpose}/${product}`);
		} else {
			if (subProductName) {
				return this.http.get<any>(this.API_BASIC_AUTH_URL + `/rates/${this.environment.institutionId}/${lender}/${loanPurpose}/${product}?subProduct=${subProductName}`);
			} else {
				return this.http.get<any>(this.API_BASIC_AUTH_URL + `/rates/${this.environment.institutionId}/${lender}/${loanPurpose}/${product}`);
			}
		}
	}

	getRatesDetails(lender: string, loanPurpose: string, product: string, subProductName: string, termBasedLoan: boolean): Observable<any> {
		const baseURL = `${this.API_BASIC_AUTH_URL}/lp/rates/${this.environment.institutionId}/${lender}/${loanPurpose}/${product}`;

		// Define the headers
				const headers = {
					headers: {
						'X-Skip-Interceptor': this.environment.auth.interceptorSkipHeader
					}
				};

		// Build the URL based on conditions
				let requestURL = baseURL;
				if (termBasedLoan) {
					return this.http.get<any>(requestURL, headers);
				} else if (subProductName) {
					requestURL += `?subProduct=${subProductName}`;
				}

		// Return the final request
				return this.http.get<any>(requestURL, headers);
	}

	getParams(
		activatedRoute: ActivatedRoute,
		keyIdentifier: KeyIdentifierModel
	) {
		activatedRoute.params.subscribe(async (params) => {
			if (!keyIdentifier.referralCode && params['referralCode']) {
				keyIdentifier.referralCode = params['referralCode'];
			}
			// For /branch route branchCode is considered as referralCode
			if (params['branchCode']) {
				keyIdentifier.channel = 'BRANCH';
				const result = await this.getPartnerDetails(
					params['branchCode']
				);
				// newApp.partnerId = data.partnerDetails[0].partnerNumber;
				// newApp.merchantName = data.partnerDetails[0].partnerName;
				if (result.partnerDetails && result.partnerDetails.length > 0) {
					const partnerDetails = result.partnerDetails[0];
					keyIdentifier.partnerId = partnerDetails.partnerNumber;
					keyIdentifier.merchantName = partnerDetails.partnerName;
					if (partnerDetails.referrerId) {
						keyIdentifier.referralCode = partnerDetails.referrerId;
					}
				}
			}
			this.sharedKeyDataService.announceIdentifierChanges(keyIdentifier);
			this.params.next(params);
		});
	}

	getQueryParams(
		activatedRoute: ActivatedRoute,
		keyIdentifier: KeyIdentifierModel,
		newApp: ApplicationModel
	) {
		activatedRoute.queryParams.subscribe(async (params) => {
			//  console.log('query params =>', params);
			if (params['promocode']) {
				keyIdentifier.promoCode = params['promocode'];
				newApp.promoCode = params['promocode'];
			}
			if (params['merchant']) {
				keyIdentifier.merchantName = params['merchant'];
				newApp.merchantName = params['merchant'];
			}
			if (params['referralCode']) {
				keyIdentifier.referralCode = params['referralCode'];
			}
			if (params['partner_number']) {
				keyIdentifier.partnerId = params['partner_number'];
				newApp.partnerId = params['partner_number'];
			}
			if (params['applicantType']) {
				keyIdentifier.applicantType = params['applicantType'];
			}
			if (params['applicationId']) {
				keyIdentifier.applicationId = params['applicationId'];
			}

			if (params['merchantId'] && params['merchantId'].length > 0) {
				keyIdentifier.merchantId = params['merchantId'];

				const result = await this.getPartnerDetails(
					keyIdentifier.merchantId
				);
				// For merchant id query param
				if (
					result &&
					result.partnerDetails &&
					result.partnerDetails.length > 0
				) {
					const partnerDetails = result.partnerDetails[0];
					newApp.partnerId = partnerDetails.partnerNumber;
					keyIdentifier.partnerId = partnerDetails.partnerNumber;
					newApp.merchantName = partnerDetails.partnerName;
					keyIdentifier.merchantName = partnerDetails.partnerName;
					if (partnerDetails.referrerId) {
						keyIdentifier.referralCode = partnerDetails.referrerId;
					}
				}
			}

			// lead generation - tracking parameters
			if (
				!keyIdentifier.referralCode ||
				keyIdentifier.referralCode === ''
			) {
				if (
					params['utm_campaign'] ||
					params['utm_medium'] ||
					params['utm_source']
				) {
					keyIdentifier.referralCode = `s=${
						params['utm_source'] || ''
					}/m=${params['utm_medium'] || ''}/c=${
						params['utm_campaign']
					}`;
				} else if (
					this.appConfig.defaultReferralCode &&
					this.appConfig.defaultReferralCode !== ''
				) {
					keyIdentifier.referralCode =
						this.appConfig.defaultReferralCode;
				}
			}

			if (params['subID']) {
				keyIdentifier.subId = params['subID'];
				newApp.leadId = params['subID'];
			} else if (params['subId']) {
				keyIdentifier.subId = params['subId'];
			} else if (params['gclid'] || params['GCLID']) {
				keyIdentifier.subId = `gclid=${
					params['gclid'] || params['GCLID']
				}`;
			}

			if (params['vin'] || params['model']) {
				const collateral = new CollateralModel();
				collateral.year = params['year'] || '';
				collateral.model = params['model'] || '';
				collateral.make = params['make'] || '';
				collateral.body = params['body'] || '';
				collateral.vin = params['vin'] || '';
				collateral.mileage = params['mileage'] || 0;
				if (params['purchasePrice']) {
					collateral.purchasePrice = params['purchasePrice'];
				}
				newApp.collateral = collateral;
			}
			this.sharedKeyDataService.announceIdentifierChanges(keyIdentifier);
			console.log('params', params);
			this.queryParams.next({
				query: params,
				keyIdentifier,
				newApp
			});
		});
	}

	// Create customer app or short app requires only Basic Auth
	// After application success, create authentication
	createApplication(
		app: ApplicationModel,
		ioBlackBoxValue: any = ''
	): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(
			this.API_BASIC_AUTH_URL + '/create-app',
			app,
			{
				headers: {
					'X-Skip-Interceptor':
					this.environment.auth.interceptorSkipHeader
				}
			}
		);
	}

	// Create customer app or short app requires only Basic Auth
	// After application success, create authentication
	createApplicationV1(
		app: ApplicationModel,
		ioBlackBoxValue: any = ''
	): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(
			this.API_BASIC_AUTH_URL + '/create-app/v1',
			app,
			{
				headers: {
					'X-Skip-Interceptor':
					this.environment.auth.interceptorSkipHeader
				}
			}
		);
	}

	// CREATE =>  POST: add a new short application to the server
	createShortApplication(
		app: ApplicationModel
	): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(
			this.API_BASIC_AUTH_URL + '/short-app',
			app,
			{
				headers: {
					'X-Skip-Interceptor':
					this.environment.auth.interceptorSkipHeader
				}
			}
		);
	}

	getExistingMemberDetails(memberData: any): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(
			this.API_BASIC_AUTH_URL + '/member/search',
			memberData,
			{
				headers: {
					'X-Skip-Interceptor':
					this.environment.auth.interceptorSkipHeader
				}
			}
		);
	}

	addConsents(applicationId: number, applicantId: number, consent: CustomerConsentModel[]): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(
			this.environment.apiUrl +
			`/consent/application/${applicationId}/applicant/${applicantId}`,
			consent
		);
	}

	findApplication(_appId: number): Observable<ApplicationModel> {
		return this.http.get<ApplicationModel>(
			this.API_APPLICATION_URL + `/${_appId}`
		);
	}

	findApplicationStatus(_appId: number): Observable<ApplicationModel> {
		return this.http.get<ApplicationModel>(
			this.API_APPLICATION_URL + `/status/${_appId}`
		);
	}

	updateApplicationStatus(_appId: number, body: any): Observable<any> {
		return this.http.put<any>(
			this.API_APPLICATION_URL + `/${_appId}/status`,
			body
		);
	}

	updateApplication(appId: number, app: any): Observable<any> {
		return this.http.put<any>(this.API_APPLICATION_URL + `/${appId}`, app);
	}

	searchApplication(
		search: SearchModel,
		isCoBoSearch = false,
		memberSearch = false
	): Observable<any> {
		let url = '/search';
		if (isCoBoSearch) {
			url = '/search/coapplicant';
		}
		if (memberSearch) {
			url = '/member/search';
		}
		return this.http.post<CSPostResponseModel>(
			this.API_BASIC_AUTH_URL + url,
			search,
			{
				headers: {
					'X-Skip-Interceptor':
					this.environment.auth.interceptorSkipHeader
				}
			}
		);
		/* mergeMap(res => {
                 const app = res.applications[0];
                 console.log('searchApplication => ', JSON.stringify(res));
                 this.model.username = app.applicationId;headers: {'X-Skip-Interceptor': environment.auth.interceptorSkipHeader}
                 this.model.grant_type = 'password';
                 this.model.password = search.lastName + search.dob + search.email + search.last4SSN;
                 this.authenticationService.authenticate(this.model).subscribe( token => {
                     console.log('authentication service => ', JSON.stringify(res));
                 });
                return of(res);
            }));*/
	}

	// Get applicants tradelines that are attached to the application
	getApplicationTradeLines(
		_appId: number,
		_productType: string
	): Observable<TradeLinesModel> {
		console.log(
			' Calling getApplicationTradeLines for ',
			_productType,
			_appId
		);
		return this.http.get<TradeLinesModel>(
			this.API_APPLICATION_URL + `/tradeline/${_productType}/${_appId}`
		);
	}

	unsubscribe(code: any, reasons: string): Observable<any> {
		return this.http.put(
			this.API_BASIC_AUTH_URL +
			`/unsubscribe/${this.environment.institutionId}?code=${code}`,
			{ reason: reasons },
			{
				headers: {
					'X-Skip-Interceptor':
					this.environment.auth.interceptorSkipHeader
				}
			}
		);
	}

	getCoBorrowerCreditReport(_appId: number): Observable<any> {
		return this.http.get<any>(
			this.API_APPLICATION_URL + `/coborrower/creditReport/${_appId}`
		);
	}

	bundleService(
		applicationId: number,
		skipOfferScreen = false
	): Observable<any> {
		const multipleAPIs = [];
		multipleAPIs.push({
			url: this.environment.apiUrl + `/application/${applicationId}`
		});
		if (!skipOfferScreen) {
			multipleAPIs.push({
				url: this.environment.apiUrl + `/offer/${applicationId}`
			});
			multipleAPIs.push({
				url: this.environment.apiUrl + `/collateral/${applicationId}`
			});
		}

		let options: any = {};
		multipleAPIs.forEach((item, index) => {
			options[index] = this.http
				.get(`${item.url}`)
				.pipe(mergeMap((response) => of({ value: response })))
				.pipe(catchError((e) => of({ error: e.error })));
		});

		return forkJoin(
			multipleAPIs.length
				? options
				: of({ error: { code: '4005', message: 'error' } })
		).pipe(
			map((res: any) => {
				res = Object.values(res);
				return { responses: res, error: (res[0] || {}).error };
			})
		);
	}

	searchPreApprovalApplicationWithOfferCode(
		data: any
	): Observable<CSPostResponseModel> {
		const params = new HttpParams({
			fromObject: data
		});

		const url = `${this.API_BASIC_AUTH_URL}/preapprovalapplication/${this.environment.institutionId}/search`;
		return this.http.get<CSPostResponseModel>(url, {
			headers: {
				'X-Skip-Interceptor':
				this.environment.auth.interceptorSkipHeader
			},
			params
		});
	}

	searchPreApprovalApplicationWithoutOfferCode(
		params: any
	): Observable<CSPostResponseModel> {
		const url = `${this.API_BASIC_AUTH_URL}/preapprovalapplication/${this.environment.institutionId}/search`;
		return this.http.get<CSPostResponseModel>(url, {
			params,
			headers: {
				'X-Skip-Interceptor':
				this.environment.auth.interceptorSkipHeader
			}
		});
	}

	getUserDetails(accountId: any, authCode: any): Observable<any> {
		const url = `${this.API_BASIC_AUTH_URL}/member/${this.environment.institutionId}/account/${accountId}`;
		return this.http.get<CSPostResponseModel>(url, {
			headers: {
				'X-Skip-Interceptor':
				this.environment.auth.interceptorSkipHeader,
				authcode: authCode
			}
		});
	}

	checkPreEligibilty(appId: number): Observable<CSPostResponseModel> {
		const url = `${this.environment.apiUrl}/application/preeligibilty/${appId}`;
		return this.http.put<CSPostResponseModel>(url, {});
	}

	checkFraudIdAnalytics(_appId: number): Observable<any> {
		return this.http.post(
			`${this.API_BASIC_AUTH_URL}/fraud/idanalytics/${_appId}`,
			{}
		);
	}

	checkFraudPreCheck(_appId: number): Observable<any> {
		return this.http.post(
			`${this.API_BASIC_AUTH_URL}/fraud/precheck/${_appId}`,
			{}
		);
	}

	checkFraud(_appId: number): Observable<any> {
		return this.http.post(`${this.API_BASIC_AUTH_URL}/fraud/${_appId}`, {});
	}

	uploadAppDocument(data: any, _appId: number): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'multipart/form-data');
		return this.http.post<any>(
			this.environment.apiUrl +
			`/documents/${this.environment.institutionId}/${_appId}/upload`,
			data,
			{ headers }
		);
	}

	getRequestedDocuments(_appId: number): any {
		return this.http.get<CSPostResponseModel>(
			this.environment.apiUrl +
			`/documents/requested/application/${_appId}`
		);
	}

	generateAddOnProductsOptions(_appId: number): any {
		return this.http.post<CSPostResponseModel>(
			this.environment.apiUrl + `/addonproducts/application/${_appId}`,
			{}
		);
	}

	updateAddOnProductsOptions(_appId: number, body: any): any {
		return this.http.put<CSPostResponseModel>(
			this.environment.apiUrl + `/addonproducts/application/${_appId}`,
			body
		);
	}

	postACHData(applicationId: number, data: any): Observable<any> {
		return this.http.post<any>(
			this.API_BASIC_AUTH_URL +
			'/payment/nofund/ach/details/' +
			applicationId,
			data
		);
	}

	validateZipCode(s: string): Observable<any> {
		const url = 'https://7MV7A9XWSE.algolia.net/1/indexes/ZIP/query';
		const body = {
			query: `${s}`,
			filters: `ZIP:'${s}'`,
			attributesToRetrieve: [
				"ZIP",
				"City",
				"ST"
			]
		}

		return this.http.post(url, body, {
			headers: {
				'X-Skip-Headers': this.environment.auth.interceptorSkipHeader,
				'X-Algolia-Api-Key': this.environment.ALGOLIA_API_KEY,
				'X-Algolia-Application-Id': this.environment.ALGOLIA_APPLICATION_ID
			}
		});
	}

	searchCompanyByName(s: string): Observable<any> {
		const url = 'https://7MV7A9XWSE.algolia.net/1/indexes/SEG/query';
		const body = {
			query: `${s}`,
			filters: 'institutionlookup:\'' + this.environment.institutionId + '_Organization\'',
			attributesToRetrieve: [
				'code',
				'value'
			]
		};

		return this.http.post(url, body, {
			headers: {
				'X-Skip-Headers': this.environment.auth.interceptorSkipHeader,
				'X-Algolia-Api-Key': this.environment.ALGOLIA_API_KEY,
				'X-Algolia-Application-Id': this.environment.ALGOLIA_APPLICATION_ID
			}
		});
	}

	pushDocs(_appId: number, lenderName: string): Observable<any> {
		return this.http.post<any>(
			`${this.API_BASIC_AUTH_URL}/send/stips/lender/${lenderName}/application/${_appId}`,
			{}
		);
	}

	internalSearchApplication(postBody) {
		return this.http.post<CSPostResponseModel>(
			`${this.environment.apiUrl}/internal-search`,
			postBody
		);
	}

	/**
	 * Service is to get list of stipulation
	 * @param _applicationId
	 */
	getStipulations(_applicationId: number) {
		return this.http.get<any>(
			this.environment.apiUrl + `/stips/${_applicationId}`
		);
	}

	getPartnerDetails(merchantId: string): Promise<any> {
		const url = `${this.environment.apiUrl}/partnerdetails/partner-details/${this.environment.institutionId}/web/${merchantId}`;
		const headers = new HttpHeaders({
			'X-Skip-Interceptor': this.environment.auth.interceptorSkipHeader
		});

		return firstValueFrom(this.http.get<any>(url, { headers }));
	}

	getMerchantSubProducts(merchantId: string = ''): Observable<any> {
		const url = `${this.environment.apiUrl}/partnerproducts/${this.environment.institutionId}/${merchantId}`;
		const headers = new HttpHeaders({
			'X-Skip-Interceptor': this.environment.auth.interceptorSkipHeader
		});
		return this.http.get(url, { headers });
	}
}
