export const config: any = {
	institutionName: "NASA",
	logoName: "https://s3.amazonaws.com/creditsnapimages/NASA/logo.png",
	logoUrl: "https://www.nasafcu.com/",
	phoneNumber: "1-888-627-2328",
	existingMemberPrompt: false,
	productSelection: false,
	multiSteps: false,
	stepsLocation: "", //top
	imageFlow: false,
	imageForCollateral: false,
	branding: {},
	askOTP: false,
	otpVerifyChannel: 'SMS', // SMS or SMS_EMAIL
	steps: {
		1: {
			title: "creditsnap Details",
			fields: {
				first_name: true,
				last_name: true,
				email: true,
				mobile_number: true,
				income: true,
				employment_length: true,
				rent_or_own: true,
				housing_cost: true,
				address_details: true,
				date_of_birth: true,
				citizenship: true,
				graduation_level: true,
				MLA: true,
				misc: true
			}
		},
		2: {
			title: "Test Details",
			fields: {
				income: true,
				employment_length: true,
				rent_or_own: true,
				housing_cost: true
			}
		},
		3: {
			title: "Personal details",
			fields: {
				income: true,
				employment_length: true,
				rent_or_own: true,
				housing_cost: true
			}
		}
	},
	defaultLoanAmount: 22500,
	isCoBorrowerRequired: true,
	isCitizenshipRequired: true,
	existingMemberFields: {
		lastName: true,
		dateOfBirth: true,
		zipCode: false,
		last4SSN: true,
		visible: true,
		firstName: false,
		email: false,
		memberNumber: false,
		existingMemberText: ""
	},
	isPermanentResidentRequired: true,
	defaultRateType: "fixed",
	preEligibilityCheck: false,
	relationshipToApplicant: false,
	vehicleUndecided: true,
	hardInquiryConsent: "modal", //modal
	consentsConfig: {
		web: {
			consent1_individual: {
				text: `By providing information in this pre-qualification request and clicking the "Continue" button below, you and
				all parties to the pre-qualification request, consent, acknowledge, and agree to the following:
				<ul>
				<li>You consent to receive electronic communications solely, regarding your pre-qualification request, subsequent application
				(if any), costs, fees, application decisions, and important account opening information (together known as electronic disclosures),
				which includes messages to the email address you provided within the request and/or application. The electronic disclosures may
				not be available in any paper or non-electronic form, so if you would like to retain a copy for your records, you must print or
				download these disclosures during the application process. You understand that to access and retain these electronic disclosures
				you must have access to the following hardware and software: 1) a computer or mobile device with Internet or mobile connectivity
				and an operating system; 2) an Internet browser which supports HTML 4.0, SSL encryption and JavaScript (e.g., the latest version
				of Edge, Firefox, Chrome or Safari); 3) software capable of opening documents in PDF format; 4) access to the email address you
				provided within; and 5) sufficient storage space to save communications or a printer to print them. You understand that you must
				consent to receive these materials electronically to submit this request.</li>
				<li>Consent to <a href="https://www.nasafcu.com/docs/default-source/privacy-terms/eagreementtscs.pdf?_ga=2.9633269.1135795595.1677876350-1851470642.1677876350" target="_blank">Electronic Communications and E-Signature Disclosure</a></li>
				<li>Consent to <a href="https://www.nasafcu.com/online-privacy-security-policy" target="_blank">Privacy and Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act to authorize NASA Federal Credit Union to obtain
				your credit profile from credit reporting agencies. You authorize NASA Federal Credit Union to obtain such information solely to
				evaluate your eligibility for certain loan product pre-qualification offers and other cross-sell offers and acknowledge that this
				is not an application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent', 'SoftInQuiry'],
			},
			consent2_individual: {
				text: "Checking this box is optional. By checking this box, you and all parties to the pre-qualification request, consent to receive email, SMS or other marketing" +
					" communications from NASA Federal Credit Union. You further expressly agree that NASA Federal Credit Union may contact you at " +
					"the contact numbers provided, including your cellular number, via live, auto dialer, prerecorded telephone call, text, chat or " +
					"email message. You further understand that your telephone provider may impose charges on you for these contacts and you are not " +
					"required to provide this consent to be effective even if your contact numbers or email address are included on a do-not-call list " +
					"or registry. You understand that your consent to receive calls is not required in order to purchase any goods or services." +
					"If you do not want to receive any marketing communications just un-check the box.",
				required: false,
				consents: ['Marketing'],
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text: `By providing information about yourself and clicking the box to the left, you consent, acknowledge, and agree to the following:
				<ul>
				<li>I consent to receive electronic communications solely, regarding my request, subsequent application (if any), costs, fees, application decisions, and
				important account opening information (together known as electronic disclosures) and includes messages to the email address
				I provided within the request and/or application.  The electronic disclosures may not be available in any paper or non-electronic form,
				so if I would like to retain a copy for my records, I must print or download these disclosures during the application process.
				I understand that to access and retain these electronic disclosures I must have access to the following hardware and software:
				1) a computer or mobile device with Internet or mobile connectivity and an operating system; 2) an Internet browser which
				supports HTML 4.0, SSL encryption and JavaScript (e.g., the latest version of Edge, Firefox, Chrome or Safari); 3) software
				capable of opening documents in PDF format; 4) access to the email address I provided within; and 5) sufficient storage space
				to save communications or a printer to print them.  I understand that I must consent to receive these materials electronically to
				submit this request.</li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				</ul>`,
				required: true,
				consents: ['eConsent'],
			},
			//preapproval
			consent4_individual: {
				text: `By providing information in this pre-qualification request and clicking the "Continue" button below, you and
				all parties to the pre-qualification request, consent, acknowledge, and agree to the following:
				<ul>
				<li>You consent to receive electronic communications solely, regarding your pre-qualification request, subsequent application
				(if any), costs, fees, application decisions, and important account opening information (together known as electronic disclosures),
				which includes messages to the email address you provided within the request and/or application. The electronic disclosures may
				not be available in any paper or non-electronic form, so if you would like to retain a copy for your records, you must print or
				download these disclosures during the application process. You understand that to access and retain these electronic disclosures
				you must have access to the following hardware and software: 1) a computer or mobile device with Internet or mobile connectivity
				and an operating system; 2) an Internet browser which supports HTML 4.0, SSL encryption and JavaScript (e.g., the latest version
				of Edge, Firefox, Chrome or Safari); 3) software capable of opening documents in PDF format; 4) access to the email address you
				provided within; and 5) sufficient storage space to save communications or a printer to print them. You understand that you must
				consent to receive these materials electronically to submit this request.</li>
				<li>Consent to <a href="https://www.nasafcu.com/docs/default-source/privacy-terms/eagreementtscs.pdf?_ga=2.9633269.1135795595.1677876350-1851470642.1677876350" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act to authorize NASA Federal Credit Union to obtain
				your credit profile from credit reporting agencies. You authorize NASA Federal Credit Union to obtain such information solely to
				evaluate your eligibility for certain loan product pre-qualification offers and other cross-sell offers and acknowledge that this
				is not an application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent', 'SoftInQuiry'],
			},
			consent1_joint: {
				text: `By selecting the box to the left, you agree that completing and submitting information in this pre-qualification on behalf ' +
				'of yourself and one or more co-borrowers indicates your intent to apply for joint credit. By selecting the box to the left,
				you also consent, acknowledge, and agree to the following:
				<ul>
				<li>I consent to receive electronic communications solely, regarding my pre-qualification request, subsequent application (if any), costs, fees, application decisions, and
				important account opening information (together known as electronic disclosures) and includes messages to the email address
				I provided within the request and/or application.  The electronic disclosures may not be available in any paper or non-electronic form,
				so if I would like to retain a copy for my records, I must print or download these disclosures during the application process.
				I understand that to access and retain these electronic disclosures I must have access to the following hardware and software:
				1) a computer or mobile device with Internet or mobile connectivity and an operating system; 2) an Internet browser which
				supports HTML 4.0, SSL encryption and JavaScript (e.g., the latest version of Edge, Firefox, Chrome or Safari); 3) software
				capable of opening documents in PDF format; 4) access to the email address I provided within; and 5) sufficient storage space
				to save communications or a printer to print them.  I understand that I must consent to receive these materials electronically to
				submit this request.</li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
				to authorize NASA Federal Credit Union to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize NASA Federal Credit Union to obtain
				such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
				final application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent', 'SoftInQuiry'],
			},
			consent2_joint: {
				text: "By checking this box, you and all parties to the pre-qualification request, consent to receive email, SMS or other marketing" +
					" communications from NASA Federal Credit Union. You further expressly agree that NASA Federal Credit Union may contact you at " +
					"the contact numbers provided, including your cellular number, via live, auto dialer, prerecorded telephone call, text, chat or " +
					"email message. You further understand that your telephone provider may impose charges on you for these contacts and you are not " +
					"required to provide this consent to be effective even if your contact numbers or email address are included on a do-not-call list " +
					"or registry. You understand that your consent to receive calls is not required in order to purchase any goods or services." +
					"If you do not want to receive any marketing communications just un-check the box.",
				required: false,
				consents: ['Marketing'],
			},
			//skipoffer joint
			consent3_joint: {
				text: "By selecting the box to the left, you agree that completing and submitting information on behalf " +
					"of yourself and one or more co-borrowers indicates your intent to apply for joint credit.",
				required: true,
				consents: ['eConsent'],
			},
		},
		teller: {
			consent1_individual: {
				text: `By providing information in this pre-qualification request and clicking the "Continue" button below, you and
				all parties to the pre-qualification request, consent, acknowledge, and agree to the following:
				<ul>
				<li>You consent to receive electronic communications solely, regarding your pre-qualification request, subsequent application
				(if any), costs, fees, application decisions, and important account opening information (together known as electronic disclosures),
				which includes messages to the email address you provided within the request and/or application. The electronic disclosures may
				not be available in any paper or non-electronic form, so if you would like to retain a copy for your records, you must print or
				download these disclosures during the application process. You understand that to access and retain these electronic disclosures
				you must have access to the following hardware and software: 1) a computer or mobile device with Internet or mobile connectivity
				and an operating system; 2) an Internet browser which supports HTML 4.0, SSL encryption and JavaScript (e.g., the latest version
				of Edge, Firefox, Chrome or Safari); 3) software capable of opening documents in PDF format; 4) access to the email address you
				provided within; and 5) sufficient storage space to save communications or a printer to print them. You understand that you must
				consent to receive these materials electronically to submit this request.</li>
				<li>Consent to <a href="https://www.nasafcu.com/docs/default-source/privacy-terms/eagreementtscs.pdf?_ga=2.9633269.1135795595.1677876350-1851470642.1677876350" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act to authorize NASA Federal Credit Union to obtain
				your credit profile from credit reporting agencies. You authorize NASA Federal Credit Union to obtain such information solely to
				evaluate your eligibility for certain loan product pre-qualification offers and other cross-sell offers and acknowledge that this
				is not an application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent', 'SoftInQuiry'],
			},
			consent2_individual: {
				text: "By checking this box, you and all parties to the pre-qualification request, consent to receive email, SMS or other marketing" +
					" communications from NASA Federal Credit Union. You further expressly agree that NASA Federal Credit Union may contact you at " +
					"the contact numbers provided, including your cellular number, via live, auto dialer, prerecorded telephone call, text, chat or " +
					"email message. You further understand that your telephone provider may impose charges on you for these contacts and you are not " +
					"required to provide this consent to be effective even if your contact numbers or email address are included on a do-not-call list " +
					"or registry. You understand that your consent to receive calls is not required in order to purchase any goods or services." +
					"If you do not want to receive any marketing communications just un-check the box.",
				required: false,
				consents: ['Marketing'],
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text: `By providing information about yourself and clicking the box to the left, you consent, acknowledge, and agree to the following:
				<ul>
				<li>I consent to receive electronic communications solely, regarding my request, subsequent application (if any), costs, fees, application decisions, and
				important account opening information (together known as electronic disclosures) and includes messages to the email address
				I provided within the request and/or application.  The electronic disclosures may not be available in any paper or non-electronic form,
				so if I would like to retain a copy for my records, I must print or download these disclosures during the application process.
				I understand that to access and retain these electronic disclosures I must have access to the following hardware and software:
				1) a computer or mobile device with Internet or mobile connectivity and an operating system; 2) an Internet browser which
				supports HTML 4.0, SSL encryption and JavaScript (e.g., the latest version of Edge, Firefox, Chrome or Safari); 3) software
				capable of opening documents in PDF format; 4) access to the email address I provided within; and 5) sufficient storage space
				to save communications or a printer to print them.  I understand that I must consent to receive these materials electronically to
				submit this request.</li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				</ul>`,
				required: true,
				consents: ['eConsent'],
			},
			//preapproval
			consent4_individual: {
				text: `By providing information in this pre-qualification request and clicking the "Continue" button below, you and
				all parties to the pre-qualification request, consent, acknowledge, and agree to the following:
				<ul>
				<li>You consent to receive electronic communications solely, regarding your pre-qualification request, subsequent application
				(if any), costs, fees, application decisions, and important account opening information (together known as electronic disclosures),
				which includes messages to the email address you provided within the request and/or application. The electronic disclosures may
				not be available in any paper or non-electronic form, so if you would like to retain a copy for your records, you must print or
				download these disclosures during the application process. You understand that to access and retain these electronic disclosures
				you must have access to the following hardware and software: 1) a computer or mobile device with Internet or mobile connectivity
				and an operating system; 2) an Internet browser which supports HTML 4.0, SSL encryption and JavaScript (e.g., the latest version
				of Edge, Firefox, Chrome or Safari); 3) software capable of opening documents in PDF format; 4) access to the email address you
				provided within; and 5) sufficient storage space to save communications or a printer to print them. You understand that you must
				consent to receive these materials electronically to submit this request.</li>
				<li>Consent to <a href="https://www.nasafcu.com/docs/default-source/privacy-terms/eagreementtscs.pdf?_ga=2.9633269.1135795595.1677876350-1851470642.1677876350" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act to authorize NASA Federal Credit Union to obtain
				your credit profile from credit reporting agencies. You authorize NASA Federal Credit Union to obtain such information solely to
				evaluate your eligibility for certain loan product pre-qualification offers and other cross-sell offers and acknowledge that this
				is not an application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent', 'SoftInQuiry'],
			},
			consent1_joint: {
				text: `By selecting the box to the left, you agree that completing and submitting information in this pre-qualification on behalf ' +
				'of yourself and one or more co-borrowers indicates your intent to apply for joint credit. By selecting the box to the left,
				you also consent, acknowledge, and agree to the following:
				<ul>
				<li>I consent to receive electronic communications solely, regarding my pre-qualification request, subsequent application (if any), costs, fees, application decisions, and
				important account opening information (together known as electronic disclosures) and includes messages to the email address
				I provided within the request and/or application.  The electronic disclosures may not be available in any paper or non-electronic form,
				so if I would like to retain a copy for my records, I must print or download these disclosures during the application process.
				I understand that to access and retain these electronic disclosures I must have access to the following hardware and software:
				1) a computer or mobile device with Internet or mobile connectivity and an operating system; 2) an Internet browser which
				supports HTML 4.0, SSL encryption and JavaScript (e.g., the latest version of Edge, Firefox, Chrome or Safari); 3) software
				capable of opening documents in PDF format; 4) access to the email address I provided within; and 5) sufficient storage space
				to save communications or a printer to print them.  I understand that I must consent to receive these materials electronically to
				submit this request.</li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
				to authorize NASA Federal Credit Union to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize NASA Federal Credit Union to obtain
				such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
				final application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent', 'SoftInQuiry'],
			},
			consent2_joint: {
				text: "By checking this box, you and all parties to the pre-qualification request, consent to receive email, SMS or other marketing" +
					" communications from NASA Federal Credit Union. You further expressly agree that NASA Federal Credit Union may contact you at " +
					"the contact numbers provided, including your cellular number, via live, auto dialer, prerecorded telephone call, text, chat or " +
					"email message. You further understand that your telephone provider may impose charges on you for these contacts and you are not " +
					"required to provide this consent to be effective even if your contact numbers or email address are included on a do-not-call list " +
					"or registry. You understand that your consent to receive calls is not required in order to purchase any goods or services." +
					"If you do not want to receive any marketing communications just un-check the box.",
				required: false,
				consents: ['Marketing'],
			},
			//skipoffer joint
			consent3_joint: {
				text: "By selecting the box to the left, you agree that completing and submitting information on behalf " +
					"of yourself and one or more co-borrowers indicates your intent to apply for joint credit.",
				required: true,
				consents: ['eConsent'],
			},
		},
	},
	hardInquiryConsentText: "You authorize NASA Federal Credit Union to obtain your credit report from a credit reporting company. You acknowledge that this " +
		"is an application for credit and authorize NASA Federal Credit Union to obtain such information solely to evaluate your credit application. You are " +
		"also consenting to allow NASA Federal Credit Union to contact you and offer you savings and benefits on other products.",
	hardInquiryConsentText_joint: "You authorize NASA Federal Credit Union to obtain your credit report and Joint Applicant's credit report   from a credit reporting company. You acknowledge that this " +
		"is an application for credit and authorize NASA Federal Credit Union to obtain such information solely to evaluate your credit application. You are " +
		"also consenting to allow NASA Federal Credit Union to contact you and offer you savings and benefits on other products.",
	underwritingStatusMessages: {
		CREDIT_CARD: {
			approved: {
				title: "Congratulations! You are now fully approved.",
				message: "Our team will reach out to you on the next business day with next steps. Thank you for your membership in NASA Federal Credit Union."
			},
			pending: {
				title: "Thank you for your application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			},
			decline: {
				title: "Thank you for your application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			},
			default: {
				title: "Thank you for your application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			},
			approved_cc: {
				title: "Congratulations! Your credit card application has been approved.",
				message: "Our team will reach out to you on the next business day with next steps. Thank you for your membership in NASA Federal Credit Union."
			},
			approved_cli: {
				title: "Congratulations! Your credit limit increase has been approved.",
				message: "Our team will reach out to you on the next business day with next steps. Thank you for your membership in NASA Federal Credit Union."
			}
		},
		Auto: {
			approved: {
				title: "Congratulations! Your Auto Loan application has been approved.",
				message: "Our team will reach out to you on the next business day with next steps. Thank you for your membership in NASA Federal Credit Union."
			},
			pending: {
				title: "Thank you for your Auto Loan application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			},
			decline: {
				title: "Thank you for your Auto Loan application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			},
			default: {
				title: "Thank you for your Auto Loan application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			}
		},
		UNSECURED: {
			approved: {
				title: "Congratulations! Your application has been approved.",
				message: "Our team will reach out to you on the next business day with next steps. Thank you for your membership in NASA Federal Credit Union."
			},
			pending: {
				title: "Thank you for your application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			},
			decline: {
				title: "Thank you for your application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			},
			default: {
				title: "Thank you for your application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			}
		},
		UNSECURED_LOC: {
			approved: {
				title: "Congratulations! Your application has been approved.",
				message: "Our team will reach out to you on the next business day with next steps. Thank you for your membership in NASA Federal Credit Union."
			},
			pending: {
				title: "Thank you for your application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			},
			decline: {
				title: "Thank you for your application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			},
			default: {
				title: "Thank you for your application!",
				message: "We are reviewing your application and will reach out to you within one business day."
			}
		}
	},
	crossSellOffersPage: {
		title: "Congratulations!! We found additional personalized pre-qualified offers for you.",
		message: ""
	},
	footerConfig: {
		footerTop: {
			BGColor: "#FFFFFF",
			FontColor: "#000000"
		},
		footerBottom: {
			BGColor: "#03243c",
			FontColor: "#FFFFF",
			copyRight: "NASA Federal Credit Union. All rights reserved.",
			phone: "1-888-627-2328"
		}
	},
	ssnPrompt: true,
	mailingAddress: true,
	minHousingCost: 350,
	iovationIntegration: true,
	socureAPIIntegration: true,
	defaultIncome: 300,
	minMax: {
		loanAmount: {
			min: 5000,
			max: 9999999
		}
	},
	poboxAllowed: false,
	promoCode: false,
	redeemCodeFields: {
		lastName: true,
		zipCode: true,
		ssn: false,
		dob: false
	},
	residenceStatusList: [
		{value: "OWN_MORTGAGE", label: "Mortgage"},
		{value: "OWN_FREE_CLEAR", label: "Own Free and Clear"},
		{value: "RENT", label: "Rent"},
		{value: "LIVING_WITH_PARENTS", label: "Live with Parents"},
		{value: "OTHER", label: "Other"}
	],
	loanPurpose: {
		CC_CASH: {
			id: "CC_CASH",
			title: "Platinum Cash Rewards Card",
			productName: "CREDIT_CARD",
			isMLARequired: false,
			isRefinance: false,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			softInquiryWithPreApproval: true,
			aboutYouPage: {
				employmentType: true,
				employmentLength: false,
				residenceLength: false,
				ssn: false,
				additionalIncome: true
			},
			addOnProducts: {
				visible: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: true,
				multiselect: true,
				code: "CREDIT_CARD",
				tradeLineText: "Do you need a balance transfer? Select the credit card balances that you want to transfer to your new credit card",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: false
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmountFormatted: 30000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			conditionalProvisioning: true,
			defaultTermsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-cash-rewards-terms-and-conditions",
			offerVariationDetails: {
				VISA_CASH: {
					termsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-cash-rewards-terms-and-conditions",
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/NASA/VISA_CASH.png",
					title: "Platinum Cash Rewards Card",
					subTitle: "",
					shortInfo: [
						"{{maxAvailableCredit}} Credit Limit",
						"Unlimited cash back – Earn 1.5% cash back on every purchase you make",
						"No Extra Fees - No annual fee, no balance transfer fee, and no foreign transaction fee",
						"Flexible redemption options – Use your cash back benefit to make deposits, pay down balances or redeem gift cards – your choice"
					],
					longInfo: "<ul>" +
						"<li>{{maxAvailableCredit}} Credit Limit</li>" +
						"<li>Unlimited cash back – Earn 1.5% cash back on every purchase you make</li>" +
						"<li>No Extra Fees - No annual fee, no balance transfer fee, and no foreign transaction fee</li>" +
						"<li>Flexible redemption options – Use your cash back benefit to make deposits, pay down balances or redeem gift cards – your choice</li>" +
						"</ul>",
				}
			},
			othersOnTitle: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "optional", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: "nodisplay", // nodisplay/optional/required,
					desiredLoanAmount: false,
					desiredTerm: false
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			cardImageSelector: {
				visible: true,
				cardImages: [
					{
						name: "86",
						subProductNames: ["VISA_CASH"],
						image: "https://creditsnapimages.s3.amazonaws.com/NASA/supernova.png"
					},
					{
						name: "87",
						subProductNames: ["VISA_CASH"],
						image: "https://creditsnapimages.s3.amazonaws.com/NASA/launch.png"
					}, {
						name: "85",
						subProductNames: ["VISA_CASH"],
						image: "https://creditsnapimages.s3.amazonaws.com/NASA/earth.png"
					}
				]
			},
			crossSellPageSubTitle: "Save even more with our low Balance Transfer rates.",
			crossSell: {
				promoText: "8.9%"
			},
			defaultOfferVariationObject: {
				purchaseIntroAPR: 0,
				purchaseIntroPeriod: "",
				purchaseAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%",
				balanceTransferIntroAPR: "",
				balanceTransferIntroPeriod: "",
				balanceTransferAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				cashAdvanceAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				penaltyAPRDesc: "Up to 20.49%.",
				foreignTransactionDesc: "1.00% of each foreign currency transaction in U.S. Dollars. " +
					"0.80% of each U.S. Dollar transaction that occurs in a foreign country",
				returnedPaymentFeeDesc: "$28",
				latePaymentFeeDesc: "$25"
			},
			desiredLoanAmount: false,
			termsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-cash-rewards-terms-and-conditions"
		},
		CC_REWARDS: {
			id: "CC_REWARDS",
			title: "Platinum Advantage Rewards Card",
			productName: "CREDIT_CARD",
			isMLARequired: false,
			isRefinance: false,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			softInquiryWithPreApproval: true,
			aboutYouPage: {
				employmentType: true,
				employmentLength: false,
				additionalIncome: true
			},
			addOnProducts: {
				visible: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: true,
				multiselect: true,
				code: "CREDIT_CARD",
				tradeLineText: "Do you need a balance transfer? Select the credit card balances that you want to transfer to your new credit card",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: false
			},
			preScreenPage: {
				grossAnnualIncome: true,
				rentMortgagePmt: true
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmountFormatted: 30000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			conditionalProvisioning: true,
			defaultTermsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-advantage-rewards-terms-and-conditions",
			offerVariationDetails: {
				VISA_REWARDS: {
					termsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-advantage-rewards-terms-and-conditions",
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/NASA/VISA_REWARDS.png",
					title: "Platinum Advantage Rewards Card",
					subTitle: "",
					shortInfo: [
						"{{maxAvailableCredit}} Credit Limit",
						"1 to $1 Rewards - Earn 1 point for every $1 you spend on purchases. Then redeem your points for travel, merchandise, gift cards, cash and more! Your points never expire",
						"No Extra Fees - No annual fee, no balance transfer fee, and no foreign transaction fee",
						"With lock/unlock capabilities, 24/7 emergency assistance, fraud protection and extended warranty coverage all included"
					],
					longInfo: "<ul>" +
						"<li>{{maxAvailableCredit}} Credit Limit</li>" +
						"<li>1 to $1 Rewards - Earn 1 point for every $1 you spend on purchases. Then redeem your points for travel, merchandise, gift cards, cash and more! Your points never expire</li>" +
						"<li>No Extra Fees - No annual fee, no balance transfer fee, and no foreign transaction fee</li>" +
						"<li>With lock/unlock capabilities, 24/7 emergency assistance, fraud protection and extended warranty coverage all included</li>" +
						"</ul>",
				}
			},
			othersOnTitle: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "optional", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: "nodisplay" // nodisplay/optional/required
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			cardImageSelector: {
				visible: true,
				cardImages: [
					{
						name: "81",
						subProductNames: ["VISA_REWARDS"],
						image: "https://creditsnapimages.s3.amazonaws.com/NASA/supernova.png"
					},
					{
						name: "82",
						subProductNames: ["VISA_REWARDS"],
						image: "https://creditsnapimages.s3.amazonaws.com/NASA/launch.png"
					}, {
						name: "80",
						subProductNames: ["VISA_REWARDS"],
						image: "https://creditsnapimages.s3.amazonaws.com/NASA/earth.png"
					}
				]
			},
			crossSellPageSubTitle: "Save even more with our low Balance Transfer rates.",
			crossSell: {
				promoText: "8.9%"
			},
			defaultOfferVariationObject: {
				purchaseIntroAPR: 0,
				purchaseIntroPeriod: "",
				purchaseAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%",
				balanceTransferIntroAPR: "",
				balanceTransferIntroPeriod: "",
				balanceTransferAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				cashAdvanceAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				penaltyAPRDesc: "Up to 20.49%.",
				foreignTransactionDesc: "1.00% of each foreign currency transaction in U.S. Dollars. " +
					"0.80% of each U.S. Dollar transaction that occurs in a foreign country",
				returnedPaymentFeeDesc: "$28",
				latePaymentFeeDesc: "$25"
			},
			desiredLoanAmount: false,
			termsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-advantage-rewards-terms-and-conditions"
		},
		CC_CLASSIC: {
			id: "CC_CLASSIC",
			title: "Classic Card",
			productName: "CREDIT_CARD",
			isMLARequired: false,
			isRefinance: false,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			softInquiryWithPreApproval: true,
			aboutYouPage: {
				employmentType: true,
				employmentLength: false,
				additionalIncome: true
			},
			addOnProducts: {
				visible: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: true,
				multiselect: true,
				code: "CREDIT_CARD",
				tradeLineText: "Do you need a balance transfer? Select the credit card balances that you want to transfer to your new credit card",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: false
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmountFormatted: 30000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			conditionalProvisioning: true,
			defaultTermsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/visa-classic-terms-and-conditions",
			offerVariationDetails: {
				VISA_CLASSIC: {
					termsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/visa-classic-terms-and-conditions",
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/NASA/VISA_CLASSIC.png",
					title: "Classic Card",
					subTitle: "",
					shortInfo: [
						"{{maxAvailableCredit}} Credit Limit",
						"No annual fee, no balance transfer fee, and no foreign transaction fee",
						"Lock/unlock capabilities, 24/7 emergency assistance, fraud protection and extended warranty coverage all included"
					],
					longInfo: "<ul>" +
						"<li>{{maxAvailableCredit}} Credit Limit</li>" +
						"<li>No annual fee, no balance transfer fee, and no foreign transaction fee</li>" +
						"<li>Low {{balanceTransferAPR}} APR*</li>" +
						"Lock/unlock capabilities, 24/7 emergency assistance, fraud protection and extended warranty coverage all included." +
						"</ul>",
				}
			},
			othersOnTitle: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "optional", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: "nodisplay" // nodisplay/optional/required
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			cardImageSelector: {
				visible: true,
				cardImages: [
					{
						name: "081",
						subProductNames: ["VISA_CLASSIC"],
						image: "https://creditsnapimages.s3.amazonaws.com/NASA/supernova.png"
					},
					{
						name: "082",
						subProductNames: ["VISA_CLASSIC"],
						image: "https://creditsnapimages.s3.amazonaws.com/NASA/launch.png"
					}, {
						name: "080",
						subProductNames: ["VISA_CLASSIC"],
						image: "https://creditsnapimages.s3.amazonaws.com/NASA/earth.png"
					}
				]
			},
			crossSellPageSubTitle: "Save even more with our low Balance Transfer rates.",
			crossSell: {
				promoText: "8.9%"
			},
			defaultOfferVariationObject: {
				purchaseIntroAPR: 0,
				purchaseIntroPeriod: "",
				purchaseAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%",
				balanceTransferIntroAPR: "",
				balanceTransferIntroPeriod: "",
				balanceTransferAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				cashAdvanceAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				penaltyAPRDesc: "Up to 20.49%.",
				foreignTransactionDesc: "1.00% of each foreign currency transaction in U.S. Dollars. " +
					"0.80% of each U.S. Dollar transaction that occurs in a foreign country",
				returnedPaymentFeeDesc: "$28",
				latePaymentFeeDesc: "$25"
			},
			desiredLoanAmount: false,
			termsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/visa-classic-terms-and-conditions"
		},
		CC_SPACEX: {
			id: "CC_SPACEX",
			title: "SpaceX Credit Card",
			productName: "CREDIT_CARD",
			hideFromDropdown: true,
			isMLARequired: false,
			isRefinance: false,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			softInquiryWithPreApproval: true,
			aboutYouPage: {
				employmentType: true,
				employmentLength: false,
				additionalIncome: true
			},
			addOnProducts: {
				visible: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: true,
				multiselect: true,
				code: "CREDIT_CARD",
				tradeLineText: "Do you need a balance transfer? Select the credit card balances that you want to transfer to your new credit card",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: false
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmountFormatted: 30000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			conditionalProvisioning: true,
			defaultTermsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-advantage-rewards-terms-and-conditions",
			offerVariationDetails: {
				VISA_SPACEX: {
					termsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-advantage-rewards-terms-and-conditions",
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/NASA/VISA_SPACEX.png",
					title: "SpaceX Credit Card",
					subTitle: "",
					shortInfo: [
						"{{maxAvailableCredit}} Credit Limit",
						"1 to $1 Rewards - Earn 1 point for every $1 you spend on purchases. Then redeem your points for travel, merchandise, gift cards, cash and more! Your points never expire",
						"No Extra Fees - No annual fee, no balance transfer fee, and no foreign transaction fee",
						"With lock/unlock capabilities, 24/7 emergency assistance, fraud protection and extended warranty coverage all included"
					],
					longInfo: "<ul>" +
						"<li>{{maxAvailableCredit}} Credit Limit</li>" +
						"<li>1 to $1 Rewards - Earn 1 point for every $1 you spend on purchases. Then redeem your points for travel, merchandise, gift cards, cash and more! Your points never expire</li>" +
						"<li>No Extra Fees - No annual fee, no balance transfer fee, and no foreign transaction fee</li>" +
						"<li>With lock/unlock capabilities, 24/7 emergency assistance, fraud protection and extended warranty coverage all included</li>" +
						"</ul>",
				}
			},
			othersOnTitle: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "optional", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: "nodisplay" // nodisplay/optional/required
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			cardImageSelector: {
				visible: true,
				cardImages: [
					{
						name: "089",
						subProductNames: ["VISA_SPACEX"],
						image: "https://www.nasafcu.com/images/default-source/credit-card-images/FALCON-9_94x6082402406-60ff-48c2-95d0-6eb20571823b.png"
					},
					{
						name: "088",
						subProductNames: ["VISA_SPACEX"],
						image: "https://www.nasafcu.com/images/default-source/credit-card-images/FALCON-HEAVY_94x60.png"
					}
				]
			},
			crossSellPageSubTitle: "Save even more with our low Balance Transfer rates.",
			crossSell: {
				promoText: "8.9%"
			},
			defaultOfferVariationObject: {
				purchaseIntroAPR: "N/A",
				purchaseIntroPeriod: "N/A",
				purchaseAPR: "9.40% - 17.90%",
				balanceTransferIntroAPR: "",
				balanceTransferIntroPeriod: "N/A",
				balanceTransferAPR: "6.90% - 17.99%.",
				cashAdvanceAPR: "11.40% - 18.00%.",
				penaltyAPRDesc: "U18.00%.",
				foreignTransactionDesc: "None",
				returnedPaymentFeeDesc: "$25",
				latePaymentFeeDesc: "$25"
			},
			desiredLoanAmount: false,
			termsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-advantage-rewards-terms-and-conditions"
		},
		CC_NSS: {
			id: "CC_NSS",
			title: "National Space Society Card",
			productName: "CREDIT_CARD",
			isMLARequired: false,
			isRefinance: false,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			softInquiryWithPreApproval: true,
			aboutYouPage: {
				employmentType: true,
				employmentLength: false,
				additionalIncome: true
			},
			addOnProducts: {
				visible: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: true,
				multiselect: true,
				code: "CREDIT_CARD",
				tradeLineText: "Do you need a balance transfer? Select the credit card balances that you want to transfer to your new credit card",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: false
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 500,
			maxLoanAmountFormatted: 30000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			conditionalProvisioning: true,
			defaultTermsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-advantage-rewards-terms-and-conditions",
			offerVariationDetails: {
				VISA_NSS: {
					termsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-advantage-rewards-terms-and-conditions",
					imageUrl: "https://creditsnapimages.s3.amazonaws.com/NASA/VISA_NSS.png",
					title: "National Space Society Credit Card",
					subTitle: "",
					shortInfo: [
						/*'{{maxAvailableCredit}} Credit Limit',*/
						"1 to $1 Rewards - Earn 1 point for every $1 you spend on purchases. Then redeem your points for travel, merchandise, gift cards, cash and more! Your points never expire",
						"No Extra Fees - No annual fee, no balance transfer fee, and no foreign transaction fee",
						"With lock/unlock capabilities, 24/7 emergency assistance, fraud protection and extended warranty coverage all included"
					],
					longInfo: "<ul>" +
						/*'<li>{{maxAvailableCredit}} Credit Limit</li>' +*/
						"<li>1 to $1 Rewards - Earn 1 point for every $1 you spend on purchases. Then redeem your points for travel, merchandise, gift cards, cash and more! Your points never expire</li>" +
						"<li>No Extra Fees - No annual fee, no balance transfer fee, and no foreign transaction fee</li>" +
						"<li>With lock/unlock capabilities, 24/7 emergency assistance, fraud protection and extended warranty coverage all included</li>" +
						"</ul>",
				}
			},
			othersOnTitle: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "optional", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: "nodisplay" // nodisplay/optional/required
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			cardImageSelector: {
				visible: true,
				cardImages: [
					{
						name: "083",
						subProductNames: ["VISA_NSS"],
						image: "https://www.nasafcu.com/images/default-source/credit-card-images/NSS_94x60.png"
					}
				]
			},
			crossSellPageSubTitle: "Save even more with our low Balance Transfer rates.",
			crossSell: {
				promoText: "8.9%"
			},
			defaultOfferVariationObject: {
				purchaseIntroAPR: 0,
				purchaseIntroPeriod: "",
				purchaseAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%",
				balanceTransferIntroAPR: "",
				balanceTransferIntroPeriod: "",
				balanceTransferAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				cashAdvanceAPR: "Diamond Cash Back VISA 10.24% - 18.24%. " +
					"VISA Platinum 6.99% - 18.99%. " +
					"Share Secured VISA Platinum 6.99% - 18.99%.",
				penaltyAPRDesc: "Up to 20.49%.",
				foreignTransactionDesc: "1.00% of each foreign currency transaction in U.S. Dollars. " +
					"0.80% of each U.S. Dollar transaction that occurs in a foreign country",
				returnedPaymentFeeDesc: "$28",
				latePaymentFeeDesc: "$25"
			},
			desiredLoanAmount: false,
			termsAndConditionsURL: "https://www.nasafcu.com/personal/credit-cards/credit-card-resources/card-details-and-terms/platinum-advantage-rewards-terms-and-conditions"
		},
		R: {
			id: "R",
			title: "Auto Refinance",
			productName: "AUTO",
			isMLARequired: false,
			vehicleUndecided: true,
			isRefinance: true,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			skipOfferScreen: false,
			orderCreditBureau: true,
			softInquiryWithPreApproval: true,
			aboutYouPage: {
				employmentType: true,
				employmentLength: false,
				additionalIncome: true
			},
			addOnProducts: {
				visible: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: true,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto",
				tradeLineText: "Select the loan that you want to refinance"
			},
			collateral: {
				required: true,
				type: "AUTO",
				yearMakeModelSelection: true,
				years: "all" // all, new, old
			},
			offer: {
				slider: false,
				downPayment: true,
				saving: true
			},
			preScreenPage: {
				grossAnnualIncome: true,
				rentMortgagePmt: true
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 5000,
			maxLoanAmountFormatted: 200000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "retailValue",
			defaultLoanAmount: 22500,
			conditionalProvisioning: true,
			othersOnTitle: true,
			offerVariationDetails: {},
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				showVin: true,
				bankDetails: true,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "optional", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: "nodisplay", // nodisplay/optional/required
					desiredLoanAmount: true,
					desiredTerm: true
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			desiredLoanAmount: true,
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment."
		},
		AU: {
			id: "AU",
			title: "Auto Purchase",
			productName: "AUTO",
			isMLARequired: false,
			vehicleUndecided: true,
			isRefinance: false,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			skipOfferScreen: false,
			orderCreditBureau: true,
			softInquiryWithPreApproval: true,
			aboutYouPage: {
				employmentType: true,
				employmentLength: false,
				additionalIncome: true
			},
			addOnProducts: {
				visible: false,
				
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: false,
				code: "AUTO",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: true,
				type: "AUTO",
				yearMakeModelSelection: true,
				years: "all" // all, new, old
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 5000,
			maxLoanAmountFormatted: 200000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "retailValue",
			defaultLoanAmount: 22500,
			conditionalProvisioning: true,
			othersOnTitle: false,
			offerVariationDetails: {},
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "optional", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: "nodisplay", // nodisplay/optional/required
					desiredLoanAmount: true,
					desiredTerm: true
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			desiredLoanAmount: true,
			crossSellPageSubTitle: ""
		},
		PP: {
			id: "PP",
			title: "Personal Loan",
			productName: "UNSECURED",
			isMLARequired: false,
			isRefinance: false,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			skipOfferScreen: false,
			orderCreditBureau: true,
			softInquiryWithPreApproval: true,
			purposeCodeModal: true,
			purposeCodeList: [
				{value: "PP^Debt Consolidation", label: "Debt Consolidation"},
				{value: "PP^Vacation", label: "Vacation"},
				{value: "PP^Home Improvement", label: "Home Improvement"},
				{value: "PP^Moving Expenses", label: "Moving Expenses"},
				{value: "PP^Taxes", label: "Taxes"},
				{value: "PP^Vehicle Repairs", label: "Vehicle Repairs"},
				{value: "PP^Medical Expenses", label: "Medical Expenses"},
				{value: "PP^Wedding", label: "Wedding"},
				{value: "PP^Funeral Expenses", label: "Funeral Expenses"},
				{value: "PP^Other", label: "Other"},
			],
			aboutYouPage: {
				employmentType: true,
				employmentLength: false,
				additionalIncome: true
			},
			addOnProducts: {
				visible: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: false,
				code: "OTHER",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			preScreenPage: {
				grossAnnualIncome: true,
				rentMortgagePmt: true
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmountFormatted: 30000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: "",
			conditionalProvisioning: true,
			offerVariationDetails: {},
			othersOnTitle: true,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "optional",
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: "nodisplay" // nodisplay/optional/required
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			desiredLoanAmount: true,
			crossSellPageSubTitle: ""
		},
		PLOC: {
			id: "PLOC",
			title: "CashLine Line of Credit",
			productName: "UNSECURED",
			isMLARequired: false,
			isRefinance: false,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			skipOfferScreen: false,
			orderCreditBureau: true,
			softInquiryWithPreApproval: true,
			purposeCodeModal: true,
			purposeCodeList: [
				{value: "PLOC^Vacation", label: "Vacation"},
				{value: "PLOC^Home Improvement", label: "Home Improvement"},
				{value: "PLOC^Moving Expenses", label: "Moving Expenses"},
				{value: "PLOC^Taxes", label: "Taxes"},
				{value: "PLOC^Vehicle Repairs", label: "Vehicle Repairs"},
				{value: "PLOC^Medical Expenses", label: "Medical Expenses"},
				{value: "PLOC^Wedding", label: "Wedding"},
				{value: "PLOC^Funeral Expenses", label: "Funeral Expenses"},
				{value: "PLOC^Other", label: "Other"},
			],
			aboutYouPage: {
				employmentType: true,
				employmentLength: false,
				additionalIncome: true
			},
			addOnProducts: {
				visible: false,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: false,
				code: "OTHER",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false,
				hideTerms: true
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmountFormatted: 30000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: "",
			conditionalProvisioning: true,
			offerVariationDetails: {},
			othersOnTitle: true,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "optional",
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: "nodisplay" // nodisplay/optional/required
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			desiredLoanAmount: true,
			crossSellPageSubTitle: ""
		},
		PPEMERGENCY: {
			id: "PPEMERGENCY",
			title: "Furlough Personal Loan",
			productName: "UNSECURED",
			isMLARequired: false,
			isRefinance: false,
			hideFromDropdown: false,
			skipOfferScreen: true,
			orderCreditBureau: false,
			termBasedLoan: false,
			aboutYouPage: {
				employmentType: false,
				employmentLength: true,
				additionalIncome: true,
				askCreditLimit: true
			},
			preScreenPage: {
				grossAnnualIncome: true,
				rentMortgagePmt: true
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: false,
				code: "OTHER",
				tradeLineText: "",
				tradeLineDisplay: "Credit Card"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			minAnnualIncomeCoBo: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 100,
			maxLoanAmount: 3000,
			hardInquiryConsentRequired: true,
			hardInquiryConsent: "on-page",
			sliderDefaultAmountType: "",
			defaultLoanAmount: 500,
			conditionalProvisioning: true,
			othersOnTitle: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: true,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "required", // nodisplay/optional/required
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: true,
					desiredLoanAmount: false,
					desiredTerm: false
				},
				employmentHistoryDuration: 12,
				residenceHistoryDuration: 12,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: true
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			crossSellPageSubTitle: "Save {{monthlySavings}} on your current loan payment.",
			defaultOfferVariationObject: {
				purchaseIntroAPR: 28,
				purchaseIntroPeriod: 23,
				purchaseAPR: 10,
				balanceTransferIntroAPR: 15,
				balanceTransferIntroPeriod: 22,
				balanceTransferAPR: 14,
				cashAdvanceAPR: 5,
				penaltyAPRDesc: 16,
				foreignTransactionDesc: 21,
				returnedPaymentFeeDesc: 13,
				latePaymentFeeDesc: 2
			}
		},
		/* PPHFA: {
			id: "PPHFA",
			title: "HFA - Personal Loan",
			productName: "UNSECURED",
			isMLARequired: false,
			hideFromDropdown: true,
			isRefinance: false,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: true,
			skipOfferScreen: false,
			orderCreditBureau: true,
			softInquiryWithPreApproval: true,
			adhocIdAnalytics_notWithFraudCall: true,
			aboutYouPage: {
				employmentType: true,
				employmentLength: false,
				additionalIncome: true
			},
			addOnProducts: {
				visible: true,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: false,
				code: "OTHER",
				tradeLineDisplay: "Auto"
			},
			collateral: {
				required: false,
				type: "OTHER"
			},
			offer: {
				slider: false,
				downPayment: false,
				saving: false
			},
			phone: "1-888-627-2328",
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmountFormatted: 30000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: "",
			defaultLoanAmount: "",
			conditionalProvisioning: true,
			offerVariationDetails: {},
			othersOnTitle: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: "optional",
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: "nodisplay" // nodisplay/optional/required
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false
				}
			},
			desiredLoanAmount: true,
			crossSellPageSubTitle: ""
		},
		PLOCDC: {
			id: 'PLOCDC',
			title: 'CashLine Line of Credit DC',
			productName: 'UNSECURED',
			hideFromDropdown: false,
			isMLARequired: false,
			isRefinance: false,
			iovation: true,
			finalSubmitNoHit: true,
			termBasedLoan: false,
			skipOfferScreen: false,
			orderCreditBureau: true,
			addOnProducts: {
				visible: true,
			},
			tradeLine: {
				isLienHolderNameAvailable: true,
				required: false,
				multiselect: false,
				code: 'UNSECURED',
				tradeLineDisplay: 'Auto'
			},
			collateral: {
				required: false,
				type: 'OTHER'
			},
			offer: {
				slider: true,
				downPayment: false,
				saving: false,
			},
			phone: '1-888-627-2328',
			minAge: 18,
			minAnnualIncome: 100,
			maxAnnualIncome: 500000,
			minLoanAmount: 1000,
			maxLoanAmountFormatted: 25000,
			minCoBorrowerAnnualIncome: 3600,
			hardInquiryConsentRequired: true,
			sliderDefaultAmountType: '',
			defaultLoanAmount: '',
			conditionalProvisioning: true,
			offerVariationDetails: {},
			othersOnTitle: false,
			finalSubmitPagesCount: 1,
			finalSubmitPages: {
				bankDetails: false,
				miscellaneousFields: {
					email: false,
					phone: false,
					homePhone: 'optional',
					mothersMaidenName: false,
					ssn: true,
					last4ssn: false,
					workPhone: false,
					balanceTransferAmount: 'nodisplay', // nodisplay/optional/required
				},
				employmentHistoryDuration: 0,
				residenceHistoryDuration: 0,
				page1: {
					reference: false,
					employmentHistory: true,
					residencyHistory: true,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
				},
				page2: {
					reference: false,
					employmentHistory: false,
					residencyHistory: false,
					declarations: false,
					assetsLiabilities: false,
					additionalIncome: false,
				}
			},
			crossSellPageSubTitle: ''
		}*/
	}
};
